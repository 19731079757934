export const convertToImageKit = (url, type = "web") => {
  if (type === "web") {
    const baseUrl = "https://ik.imagekit.io/toppersnotes/media";
    const append = url.split("/Gallery")[1];

    return baseUrl + append;
  } else if (type === "app") {
    const baseUrl = "https://ik.imagekit.io/toppersnotes/app";
    const append = url.split("/images")[1];

    return baseUrl + append;
  }
};
