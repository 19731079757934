/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import Link from "next/link";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Image from "next/image";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import SearchForm from "./SearchForm";
import useWindowSize from "../../hooks/useWindowSize";
import { convertToImageKit } from "../../utils/convert-to-imagekit";
import styles from "./Navbar.module.scss";

const header =
  "https://toppersnotes-web.s3.ap-south-1.amazonaws.com/Gallery/Thumbnails/tn-logo-header.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Header({ data }) {
  const size = useWindowSize();
  const [currentAffairs, setCurrentAffairs] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);

  useEffect(() => {
    if (data) {
      data.map((item) => {
        item.href = `/categories/${item.slug}`;
      });

      setCategoriesList([...data]);
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    const getCurrentAffairs = async () => {
      const url = "https://api.toppersnotes.com/api/ca/get-offerings";
      let data = await fetch(url);
      let current_Affairs = await data.json();
      if (current_Affairs) {
        current_Affairs.map((c_Affairs) => {
          c_Affairs.href =
            c_Affairs &&
            c_Affairs.type === "static" &&
            c_Affairs.view === "simple"
              ? `/current-affairs/${c_Affairs.slug}`
              : c_Affairs.type === "dynamic" && c_Affairs.view === "detailed"
              ? `/current-affairs/english-current-affair`
              : c_Affairs.type === "dynamic" && c_Affairs.view === "simple"
              ? `/current-affairs/articles/${c_Affairs.slug}`
              : "#";
        });
        setCurrentAffairs(current_Affairs);
      }
    };

    getCurrentAffairs();
  }, []);

  const myLoader = ({ src }) => {
    return src + `?tr=w-${size.width}`;
  };

  return (
    <Popover className="relative bg-white">
      <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
        <div>
          <a href="#" className="flex">
            <span className="sr-only">Workflow</span>
            <Link href="/">
              <Image
                loader={myLoader}
                width={160}
                height={65}
                src={convertToImageKit(header)}
                alt="Navbar"
                priority={true}
              />
            </Link>
          </a>
        </div>
        <div className="-mr-2 -my-2 md:hidden">
          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span className="sr-only">Open menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <div className="hidden md:flex-auto md:flex md:items-center md:justify-between">
          <Popover.Group as="nav" className="flex space-x-10">
            {/* <Link
              href="/featured/"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Featured Courses
            </Link> */}

            <div className={`${styles.hoverTriger} relative md:!ml-3`}>
              <div
                className={`text-gray-900 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                <Link
                  href="/categories"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  <a>Exam Store</a>
                </Link>
                <ChevronDownIcon
                  className={classNames(
                    "text-gray-600",
                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                  )}
                  aria-hidden="true"
                />
              </div>

              <div
                className={`${styles.hoverTarget} absolute z-10 left-1/2 transform -translate-x-1/2 w-screen max-w-xs `}
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <ul
                    role="list"
                    className="relative grid gap-2 bg-white p-2 sm:gap-2 sm:p-2 overflow-y-auto max-h-340"
                  >
                    {categoriesList.map((item, i) => {
                      return (
                        <Link
                          key={item.id}
                          href={item.href}
                          className="-m-3 p-2 flex items-center rounded-md text-base font-medium text-gray-500 hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <li
                            key={item.name}
                            className="flow-root p-2 hover:bg-gray-50"
                          >
                            <a className="hover:font-semibold hover:text-black hover:opacity-100 transition duration-700 ease-in-out transform hover:scale-110">
                              {item.name}
                            </a>
                          </li>
                        </Link>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div
              className={`${styles.hoverTriger} relative md:!ml-3 lg:!ml-12`}
            >
              <div
                className={`text-gray-900 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                <Link
                  href="/current-affairs"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  <a>Current Affairs</a>
                </Link>
                <ChevronDownIcon
                  className="text-gray-600 ml-2 h-5 w-5 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </div>

              <div
                className={`${styles.hoverTarget} absolute z-50 left-1/2 transform -translate-x-1/2 w-screen max-w-xs sm:px-0`}
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <ul
                    role="list"
                    className="relative grid gap-2 bg-white p-2 sm:gap-2 sm:p-2"
                  >
                    {currentAffairs.map((item) => (
                      <Link
                        key={item.id}
                        href={item.href}
                        className="-m-3 p-2 flex items-center rounded-md text-base font-medium text-gray-500 hover:bg-gray-50 transition ease-in-out duration-150"
                      >
                        <li
                          key={item.name}
                          className="flow-root p-2 hover:bg-gray-50"
                        >
                          <a className="hover:font-semibold hover:text-black hover:opacity-100 transition duration-700 ease-in-out transform hover:scale-110">
                            {item.name}
                          </a>
                        </li>
                      </Link>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="md:!ml-3 lg:!ml-12">
              <Link
                href="/contact"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Contact Us
              </Link>
            </div>
          </Popover.Group>
          <div className="flex-1 md:flex-auto flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
            <SearchForm />
          </div>
        </div>
      </div>

      {/* <-- Mobile Screen --> */}

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-20 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-2">
              <div className="flex items-center justify-between">
                <div>
                  <Link href="/">
                    <Image
                      loader={myLoader}
                      width={160}
                      height={65}
                      src={convertToImageKit(header)}
                      alt="Navbar"
                      priority={true}
                    />
                  </Link>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <Popover.Group as="nav" className="flex-col space-x-10">
                  <Link href="/featured/">
                    <div className="p-2 text-gray-500 hover:text-gray-900 text-base font-medium">
                      Featured Courses
                    </div>
                  </Link>
                  <Popover className="!ml-0 mt-3 ">
                    {({ open }) => (
                      <>
                        <div className="flex justify-between w-100">
                          <Popover.Button
                            className={classNames(
                              open ? "text-gray-900" : "text-gray-500",
                              "w-100 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            )}
                          >
                            <div className="flex justify-between w-100 p-2">
                              <div>Exam Store</div>
                              <div>
                                {open ? (
                                  <ChevronUpIcon
                                    className={classNames(
                                      open ? "text-gray-600" : "text-gray-400",
                                      "ml-2 h-5 w-5 group-hover:text-gray-500"
                                    )}
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ChevronDownIcon
                                    className={classNames(
                                      open ? "text-gray-600" : "text-gray-400",
                                      "ml-2 h-5 w-5 group-hover:text-gray-500"
                                    )}
                                    aria-hidden="true"
                                  />
                                )}
                              </div>
                            </div>
                          </Popover.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="relative mt-3 px-2 sm:px-0">
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <ul
                                role="list"
                                className="relative grid gap-2 bg-white p-2 sm:gap-2 max-h-340 overflow-y-auto"
                              >
                                {categoriesList.map((item) => {
                                  return (
                                    <Link
                                      key={item.id}
                                      href={item.href}
                                      className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
                                    >
                                      <li
                                        key={item.name}
                                        className="flow-root p-3 hover:bg-gray-50"
                                      >
                                        <a>{item.name}</a>
                                      </li>
                                    </Link>
                                  );
                                })}
                              </ul>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                  <Popover className="!ml-0 mt-3">
                    {({ open }) => (
                      <>
                        <div className="flex justify-between w-100">
                          <Popover.Button
                            className={classNames(
                              open ? "text-gray-900" : "text-gray-500",
                              "w-100 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            )}
                          >
                            <div className="flex justify-between w-100 p-2">
                              <div>Current Affairs</div>
                              <div>
                                {open ? (
                                  <ChevronUpIcon
                                    className={classNames(
                                      open ? "text-gray-600" : "text-gray-400",
                                      "ml-2 h-5 w-5 group-hover:text-gray-500"
                                    )}
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ChevronDownIcon
                                    className={classNames(
                                      open ? "text-gray-600" : "text-gray-400",
                                      "ml-2 h-5 w-5 group-hover:text-gray-500"
                                    )}
                                    aria-hidden="true"
                                  />
                                )}
                              </div>
                            </div>
                          </Popover.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="relative mt-3 px-2 sm:px-0">
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <ul
                                role="list"
                                className="relative grid gap-2 bg-white p-4 sm:gap-2 max-h-340 overflow-y-auto"
                              >
                                {currentAffairs.map((item) => (
                                  <Link
                                    key={item.id}
                                    href={item.href}
                                    className="-m-3 p-2 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                    <li
                                      key={item.name}
                                      className="flow-root p-2 hover:bg-gray-50"
                                    >
                                      <a>{item.name}</a>
                                    </li>
                                  </Link>
                                ))}
                              </ul>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                  <Link href="/contact">
                    <div className="!ml-0 mt-3 p-2 text-gray-500 hover:text-gray-900 text-base font-medium">
                      Contact Us
                    </div>
                  </Link>
                </Popover.Group>
              </div>
            </div>
            <div className="py-6 px-5">
              <SearchForm />
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default Header;
